import styled from "styled-components";

export const OutlinedButton = styled.button`
  border: 3px solid #dfcbb7 !important;
  background: none;
  outline: none;
  border: none;
  border-radius: 25px;
  padding: 3px 15px;
  font-size: 16px;

  :hover {
    cursor: pointer;
  }
`;
