import styled from "styled-components";

export const SmallButton = styled.button`
  background: #dfcbb7;
  outline: none;
  border: none;
  border-radius: 25px;
  padding: 4px 16px;
  font-size: 16px;

  :hover {
    cursor: pointer;
  }
`;
