import styled from "styled-components";

export const Button = styled.button`
  background: #dfcbb7;
  outline: none;
  border: none;
  border-radius: 25px;
  padding: 8px 25px;
  font-size: 25px;

  :hover {
    cursor: pointer;
  }
`;
